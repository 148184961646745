<template>
  <div class="attestation">
    <div class="line"></div>
    <div class="form">
      <div class="text-con">姓名</div>
      <div class="input-con-1">
        <input type="text" placeholder="请填写真实姓名" v-model="name" />
      </div>
      <div class="text-con mt-30">手机电话</div>
      <div class="input-con-1">
        <input type="text" placeholder="请填写11位电话号码" v-model="mobile" />
      </div>
      <div class="text-con mt-30">验证码</div>
      <div class="input-con-2">
        <input type="text" placeholder="请输入号码" v-model="code" />
        <div class="second_down" v-if="is_code">{{ second }}秒重发</div>
        <div class="code_btn" @click="getCode" v-else>获取验证码</div>
      </div>
      <div class="text-con mt-30">所属区域</div>
      <van-field
        readonly
        clickable
        :value="value"
        placeholder="选择所属区域"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <div class="text-con mt-30">工作单位</div>
      <div class="input-con-1">
        <input type="text" placeholder="请填写工作单位" v-model="workUnit" />
      </div>
    </div>
    <div class="btn" @click="submit">提交</div>
  </div>
</template>

<script>
import { postUser, getCode,getName } from "../api";
import { mapActions } from "vuex";
export default {
  name: "attestation",
  data() {
    return {
      second: 60,
      is_code: false,
      name: "",
      mobile: "",
      code: "",
      address: "",
      workUnit: "",
      value: '',
      showPicker: false,
      columns: [],
      columnsId:[],
      area_id:'',
    };
  },
  created() {
    this.init()
  },
  methods: {
    init(){
      let params = {};
      getName(params).then((res) => {
          this.columns=res.data.items.map( e=>{
            return e.title
          })
          this.columnsId =res.data.items.map( e=>{
            return e.id
          })
      });
    },

    getCode() {
      if (!this.mobile) {
        this.$toast.fail("请先输入号码");
      } else {
        let params = {
          mobile: this.mobile,
        };
        getCode(params).then((res) => {
          console.log(res);
          this.$toast.success("已发送");
          (this.second = 60), (this.is_code = true);
          this.codeCountDown();
        });
      }
    },
    toAttestation() {
      this.$router.push("/attestation");
    },
     onConfirm(value,index) {
      this.value = value;
      this.area_id = this.columnsId[index]
      console.log(this.area_id)
      this.showPicker = false;
    },
    submit() {
      if (!this.name) {
        this.$toast.fail("请输入真实姓名");
      } else if (!this.mobile) {
        this.$toast.fail("请输入号码");
      } else if (!this.code) {
        this.$toast.fail("请输入验证码");
      } else {
        let params = {
          realname: this.name,
          mobile: this.mobile,
          code: this.code,
          area_id:this.area_id,
          work_unit:this.workUnit,
          token: window.localStorage.getItem("token"),
        };
        postUser(params).then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$toast.success("实名成功");
            this.getUserInfo();
            setTimeout(() => {
              this.$router.replace("/Mine");
            }, 1200);
          }
        });
      }
    },
    ...mapActions(["getUserInfo"]),
  },
};
</script>

<style scoped lang="less">
* {
  background-color: #fff;
}
.attestation {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .line {
    width: 100%;
    height: 0.2rem;
    background-color: #f7f7f7;
  }
  .form {
    width: 100%;
    padding: 0.24rem 0.5rem;
    .text-con {
      position: relative;
      height: 0.8rem;
      line-height: 0.8rem;
      color: #666666;
      font-size: 0.3rem;
      letter-spacing: 0.02rem;
      &:after{
        content: ' ';
        position: absolute;
        left: -0.2rem;
        top: 0.34rem;
        width: .1rem;
        height: .1rem;
        background-color: #3B93FF;
        border-radius: .25rem;
      }
    }
    .input-con-1 {
      width: 100%;
      height: 0.8rem;
      padding: 0.2rem 0;
      border-bottom: 0.01rem solid rgba(179, 179, 179, 0.4);
      input {
        width: 100%;
        height: 100%;
        border: none;
      }
      input::-webkit-input-placeholder { 
        /* WebKit browsers */ 
        color: #999999; 
      } 
      input:-moz-placeholder { 
        /* Mozilla Firefox 4 to 18 */ 
        color: #999999; 
      } 
      input::-moz-placeholder { 
        /* Mozilla Firefox 19+ */ 
        color: #999999; 
      } 
      input:-ms-input-placeholder { 
        /* Internet Explorer 10+ */ 
        color: #999999; 
      }
    }
    .input-con-2 {
      width: 100%;
      height: 0.8rem;
      padding: 0.2rem 0;
      border-bottom: 0.01rem solid rgba(179, 179, 179, 0.4);
      display: flex;
      justify-content: space-between;
      input {
        width: 50%;
        height: 100%;
        border: none;
      }
      /* 获取验证码 */
      .code_btn {
        width: 2rem;
        height: 0.72rem;
        text-align: center;
        line-height: 0.72rem;
        color: #ffffff;
        // border: 0.01rem solid #41a1f0;
        font-size: 0.28rem;
        border-radius: 0.1rem;
        position: relative;
        top: -0.2rem;
        background:#41a1f0 ;
      }
      .second_down {
        width: 2rem;
        height: 0.72rem;
        text-align: center;
        line-height: 0.72rem;
        background-color: #eee;
        color: #888;
        font-size: 0.28rem;
        border-radius: 0.1rem;
        position: relative;
        top: -0.2rem;
      }
    }
    .mt-30 {
      margin-top: 0.3rem;
    }
  }
  .btn {
    width: 6rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    color: #fff;
    font-size: 0.32rem;
    letter-spacing: 0.02rem;
    background-color: #3B93FF;
    margin: 1rem auto 0;
    border-radius: 0.08rem;
  }
}
</style>
